<template>
  <div align="center">
    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <div class="img-a">
        <div>
          <v-row>
            <v-col cols="5" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/livecasino/101')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + '101.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col
              v-if="$store.state.idmoneda != 1"
              cols="5"
              sm="3"
              md="2"
              lg="2"
              xl="2"
            >
              <div
                class="mx-0 px-0"
                @click="$router.push('/casinoindex')"
                style="cursor: pointer"
              >
                <v-img
                  style="border-radius: 10px"
                  src="https://trackandraces.com/dominio/tr/mjuegos.png"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col
              v-if="$store.state.idmoneda == 1 && $store.state.idsb == 13"
              cols="5"
              sm="3"
              md="2"
              lg="2"
              xl="2"
            >
              <div
                class="mx-0 px-0"
                @click="$router.push('/casinoindex')"
                style="cursor: pointer"
              >
                <v-img
                  style="border-radius: 10px"
                  src="https://trackandraces.com/dominio/tr/mjuegos.png"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div>
         <v-row class="pt-3">
          <v-col cols="12" sm="12" md="12" lg="12">
            <div>
              <v-toolbar
                class="rounded"
                dense
                flat
                :color="colors.error"
                height="40px"
              >
                <v-icon
                  class="white--text"
                  @click="$router.push('/promoflash')"
                >
                  large mdi-file-sign</v-icon
                >
                <v-toolbar-title class="ml-3 white--text text-uppercase"
                  >¡¡Slots Clásicos vs Modernos!!</v-toolbar-title
                >
              </v-toolbar>

              <v-row class="mt-1 ml-1">
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="$router.push('/gamep/Super-7s%20/vs5super7')"
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5super7.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/Juicy-Fruits/vs50juicyfr')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs50juicyfr.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/Hot-to%20Burn/vs5hotburn')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5hotburn.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/Fire-Strike%202/vs10firestrike2')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs10firestrike2.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/Fire-Hot%2020/vs20fh')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs20fh.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="$router.push('/gamep/Hot-To%20Burn%20Multiplier/vs5hotbmult')"
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5hotbmult.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="$router.push('/gamep/Hand-of%20Midas%202/vs20midas2')"
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs20midas2.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push(
                        '/gamep/Sweet-Kingdom/vs20clustcol'
                      )
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs20clustcol.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push(
                        '/gamep/Crank-it%20Up/vs20crankit'
                      )
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs20crankit.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push(
                        '/gamep/6-Jokers/vs5magicdoor'
                      )
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5magicdoor.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                
              </v-row>
            </div>
          </v-col>
        </v-row> 

        <v-row class="pt-3">
          <v-col cols="12" sm="12" md="12" lg="12">
            <div>
              <v-toolbar
                class="rounded"
                dense
                flat
                :color="colors.warning"
                height="40px"
              >
                <!-- <v-icon 
                @click="$router.push('/promoflash')">
                large
                color="white"
                mdi-file-sign</v-icon> -->
                <v-toolbar-title class="ml-3 black--text text-uppercase"
                  >Nuevos Lanzamientos</v-toolbar-title
                >
              </v-toolbar>

              <v-row class="mt-1 ml-1">
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/vs5magicdoor/vs5magicdoor')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5magicdoor.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="$router.push('/gamep/vs12scode/vs12scode')"
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs12scode.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="
                      $router.push('/gamep/vs5hotbmult/vs5hotbmult')
                    "
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5hotbmult.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="3" md="2" lg="2" xl="2">
                  <div
                    class="cc"
                    @click="$router.push('/gamep/vs5jjwild /vs5jjwild ')"
                    style="cursor: pointer"
                  >
                    <v-img
                      eager
                      style="border-radius: 10px"
                      :src="imageUrl + 'vs5jjwild.png'"
                      width="325px"
                    ></v-img>
                  </div>
                </v-col>
                <!-- <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/Wild-Beach%20Party/vs20bchprty')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vs20bchprty.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/Juicy-Fruits%20Multihold/vs50jfmulthold')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vs50jfmulthold.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/Candy-Jar%20Clusters/vs20cjcluster')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vs20cjcluster.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/Blazing-Wilds%20Megaways/vswaysfirewmw')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vswaysfirewmw.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/Year-Of%20The%20Dragon%20King/vs20yotdk')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vs20yotdk.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col>
            
            <v-col cols="6" sm="3" md="2" lg="2" xl="2">
              <div
                class="cc"
                @click="$router.push('/gamep/The-Big%20Dawgs/vs20bigdawgs')"
                style="cursor: pointer"
              >
                <v-img
                  eager
                  style="border-radius: 10px"
                  :src="imageUrl + 'vs20bigdawgs.png'"
                  width="325px"
                ></v-img>
              </div>
            </v-col> -->
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <div style="height: 100vh" v-if="overlay"></div>
      <v-row class="mt-1 pt-3">
        <v-col cols="12" sm="12" md="12" lg="12">
          <div>
            <v-toolbar
              class="rounded"
              dense
              flat
              :color="colors.perfil"
              height="40px"
            >
              <v-toolbar-title class="white--text text-uppercase"
                >- Maquinitas -</v-toolbar-title
              >

              <v-spacer></v-spacer>
            </v-toolbar>

            <v-row class="mt-1">
              <v-col
                cols="6"
                sm="3"
                md="2"
                lg="2"
                xl="2"
                v-for="(ite, i) in newGames"
                :key="i + 'b'"
              >
                <div
                  class="cc"
                  @click="openGame(ite.gameName, ite.gameID)"
                  style="cursor: pointer"
                >
                  <v-img
                    eager
                    class="img-b"
                    style="border-radius: 10px"
                    :src="imageUrl + ite.gameID + '.png'"
                    width="300px"
                  ></v-img>
                  <h5 class="c-text text-center mt-2 white--text">
                    {{ ite.gameName }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="$store.state.filter" scrollable max-width="702px">
      <v-card>
        <v-card-title>Filtro</v-card-title>
        <v-card-text>
          <v-btn
            v-for="(item, idx) in gamesNames"
            class="mx-2 mb-3"
            :key="idx"
            depressed
            @click="goTo(item)"
            >{{ item }}</v-btn
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$store.state.filter = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
      :style="$vuetify.breakpoint.mdAndDown ? 'bottom:60px' : ''"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <MisCuentas />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import MisCuentas from "../components/MisCuentas.vue";
export default {
  data() {
    return {
      gamesNames: [],
      gameUrl: null,
      imageUrl: "https://api.prerelease-env.biz/game_pic/rec/325/",
      openIframe: false,
      overlay: false,
      newGames: [],
      newLive: [],
      filter: false,
      fab: false,
      color: false,
    };
  },
  mounted() {
    axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.nombre = response.data.success.name;
    });
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
    this.getGameList();
    this.getSaldo();
    //console.log(this.saldos);
  },
  updated() {
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
  },
  methods: {
    ...mapActions(["getSaldo"]),
    openGame(name, code) {
      this.$router.push(`/gamep/${name.replace(" ", "-")}/${code}`);
    },
    fScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.color = top > 5;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    goTo(item) {
      this.$store.state.filter = false;
      this.$vuetify.goTo("#" + item, {
        duration: 500,
        easing: "easeInOutCubic",
      });
      // setTimeout(()=>{
      //   this.$vuetify.goTo('#'+item)
      // },1000)
    },
    getGameList() {
      if (this.$store.state.idpos == 0) {
        window.location.replace("#/casinoindex");
      }

      let games = {};
      this.overlay = true;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache",
      };
      axios({
        method: "post",
        headers: headers,
        url: `https://apitr.xyz/onlineapi/api/getslot`,
        data: {
          secureLogin: "tar_trackandraces",
          hash: "1b84556c24fe29aaf241363909a6fdfd",
        },
      }).then((e) => {
        this.newGames = e.data.gameList;
        games = this.newGames;
        const videoSlots = this.newGames.filter((element) => {
          return element.typeDescription === "Video Slots";
        });
        this.newGames = videoSlots.slice(9);

        const liveCasino = games.filter((element) => {
          return element.typeDescription === "Live games";
        });
        this.newLive = liveCasino;
        //console.log(liveCasino);
        //this.newGames.splice(this.newGames.length - 60);
        //console.log(e.data.gameList);
        this.overlay = false;
      });
    },
    filterGames(array) {
      let games = {};
      let gamesNames = [];
      array.forEach((e) => {
        /* if (e.technology == "html5") {
                  if (games[e.maker] == undefined) {
                    games[e.maker] = {};
                    gamesNames.push(e.maker);
                  }
        
                  if (games[e.maker][e.type] == undefined) {
                    games[e.maker][e.type] = [];
                  }
        
                 // games[e.technology][e.typeDescription].push(e);
                 
                }*/
        games.push(e);
        gamesNames.push(e.technology);
      });

      const videoSlots = games.filter((element) => {
        return element.typeDescription === "Video Slots";
      });
      this.gamesNames = gamesNames;
      this.newGames = videoSlots;
    },
  },
  computed: {
    colors() {
      return this.$store.state.colors;
    },
  },
  components: { MisCuentas },
};
</script>

<style>
.cc {
  position: relative;
}

.img-b {
  margin: auto;

  display: flex;
  width: 100px;
}

.img-d {
  justify-content: center;
}

.c-img {
  width: 100px;
  display: flex;
  margin: auto;
  z-index: 2;
  right: 0;
  left: 0;
  position: absolute;
  margin-top: 5px;
}
</style>
